<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required } from "vuelidate/lib/validators";
import { fetchSettings } from "./api";
export default {
  page: {
    title: "Paramètres d'entreprise",
    meta: [{ name: "description", content: appConfig.description }],
  },
  import: { fetchSettings },
  components: { Layout, PageHeader, ckeditor: CKEditor.component },
  data() {
    return {
      title: "Paramètres d'entreprise",

      reportsSettingsForm: {
        report_paper_head: "",
        report_paper_foot: "",
      },
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",

      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | l      ink image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      imagePreview: null,
      showPreview: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/settings",
        },
        {
          text: "Paramètres d'entreprise",
          active: true,
        },
      ],
    };
  },

  validations: {
    reportsSettingsForm: {
      report_paper_head: { required },
      report_paper_foot: { required },
      // : { required }
    },
  },

  mounted() {
    this.populateCompanySettings();
  },

  methods: {
    showCompanyLogo(configs) {
      this.imagePreview = configs.company_logo;
      this.showPreview = true;
    },
    populateCompanySettings() {
      fetchSettings()
        .then(({ data }) => {
          var configs = data.original.data;
          this.reportsSettingsForm.report_paper_head = configs.report_paper_head;
          this.reportsSettingsForm.report_paper_foot = configs.report_paper_foot;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      this.$http
        .post("/system/settings/store", this.reportsSettingsForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              // setTimeout(function () {
              //   router.push({ name: "base.contracts.contracts.index" });
              // }, 3000);
              this.populateCompanySettings();
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form
              class="needs-validation"
              @submit.prevent="formSubmit"
              enctype="multipart/form-data"
            >
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Entête de page *</h4>
                      <p class="card-title-desc">
                        Cette partie sera affichée comme <b>entête de page</b> sur tous les rapports.
                      </p>
                      <!-- Editor -->
                      <ckeditor
                        v-model="reportsSettingsForm.report_paper_head"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Pied de page *</h4>
                      <p class="card-title-desc">
                        Cette partie sera affichée comme <b>pied de page</b>   sur tous les rapports.
                      </p>
                      <!-- Editor -->
                      <ckeditor
                        v-model="reportsSettingsForm.report_paper_foot"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  :disabled="$v.reportsSettingsForm.$invalid"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
